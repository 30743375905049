import Vue from 'vue';

Vue.mixin({
    computed:{
        isTeacher(){
            return  this.$store.state.info.memberInfo.role === 'organization_teacher' || this.$store.state.info.memberInfo.role === 'organization_admin'
        },
        isManage(){
             return this.$store.state.info.memberInfo.role === 'organization_admin'
        }
    }
});
