import Vue from 'vue'
import layout from './views/layout/layout.vue'
import router from './router'
import ajax from './ajax/ajax.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/common.scss'
import './filter/filter.js'
import store from './store/index';
import bus from './plugins/bus';
import './mixin/mixin'

Vue.use(bus);
Vue.use(ElementUI);
Vue.use(ajax);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(layout)
}).$mount('#app')
