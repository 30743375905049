import ajax from '@/ajax/ajax.js';

const state = {
    memberInfo: {},
    token: '',
    school: [],
    countTea: 0,
    countStu: 0,
    countComplete: 0
};

const mutations = {
    setMemberInfo(state, data) {
        state.memberInfo = data;
    },

    setToken(state, data) {
        state.token = data;
    },
    setSchool(state, data) {
        state.school = data;
    },
    setUnCompleteCountTea(state, data) {
        state.countTea = data;
    },
    setUnCompleteCountStu(state, data) {
        state.countStu = data;
    },
    setCountComplete(state, data) {
        state.countComplete = data;
    }
};

const actions = {
    setMemberInfoAsync({ commit }, data) {
        commit('setMemberInfo', data);
    },
    setSchoolAsync({ commit }, data) {
        commit('setSchool', data);
    },
    setUnCompleteCountAsyncTea({ commit }, data) {
        commit('setUnCompleteCountTea', data);
    },
    setUnCompleteCountAsyncStu({ commit }, data) {
        commit('setUnCompleteCountStu', data);
    },
    setCountCompleteAsync({ commit }, data) {
        commit('setCountComplete', data);
    }
};

export default {
    state,
    mutations,
    actions
};
